var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { staticClass: "switch-wrap" }, [
      _vm.label
        ? _c(
            "span",
            {
              staticClass: "form-label mr-3",
              class: { "text-muted": _vm.disabled }
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "switch",
          staticClass: "form-switch",
          class: !_vm.disabled ? _vm.style : "events-disable",
          on: { click: _vm.onClick }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm._value,
                expression: "_value"
              }
            ],
            attrs: { type: "checkbox", hidden: "" },
            domProps: {
              checked: Array.isArray(_vm._value)
                ? _vm._i(_vm._value, null) > -1
                : _vm._value
            },
            on: {
              change: [
                function($event) {
                  var $$a = _vm._value,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm._value = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm._value = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm._value = $$c
                  }
                },
                function($event) {
                  return _vm.$emit("change")
                }
              ],
              blur: function($event) {
                return _vm.$emit("blur")
              },
              focus: function($event) {
                return _vm.$emit("focus")
              }
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }