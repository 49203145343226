<template>
  <div class="form-group">

    <div class="switch-wrap">
      <span class="form-label mr-3"
            :class="{'text-muted': disabled}"
            v-if="label">{{label}}</span>

      <div class="form-switch"
           ref="switch"
           @click="onClick"
           :class="!disabled ? style : 'events-disable'">

        <input type="checkbox" hidden
               @change="$emit('change')"
               @blur="$emit('blur')"
               @focus="$emit('focus')"
               v-model="_value">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      icon: String,
      value: Boolean,
      label: [String, Number],
      loading: Boolean,
      disabled: {
        type: Boolean,
        default: false
      },
      valid: {
        type: Boolean,
        default: true
      },
      warning: {
        type: Boolean,
        default: false
      },
      success: {
        type: Boolean,
        default: false
      },
      checked: {
        type: Boolean,
        default: undefined
      }
    },
    computed: {
      _value: {
        get () { return this.value },
        set (value) { this.$emit('input', value); }
      },
      style() {
        return {
          active: this.checked !== undefined ? this.checked : this._value,
          'switch-success': this.success,
          'switch-warning': this.warning,
          'switch-danger': !this.valid,
          'switch-primary': this.valid
        }
      }
    },
    data() {
      return {
        _disable: false
      }
    },
    methods: {
      onClick(event) {
        event.preventDefault = () => { this._disable = true; };

        this.$emit('click', event);

        if(!this._disable)
          this._value = !this._value;

        this._disable = false;
      }
    }
  }
</script>
